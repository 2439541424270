html, body, #root {
  height: 100%;
  margin: 0;
}

.site-content {
  flex: 1 0 auto;
}

#root {
  display: flex;
  flex-direction: column;
}