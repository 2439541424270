.homePage {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

.introSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.textContainer {
  max-width: 600px;
  align-self: start;
}

.responsiveImage {
  max-width: 50%;
  height: auto;
  border-radius: 8px; /* Optional: if you want rounded corners on your images */
}

.homeFooter {
  background-color: #f1f1f1; /* light grey background for the footer */
  padding: 20px;
  text-align: center;
}

/* Add media queries as needed for responsiveness */
@media (max-width: 768px) {
  .introSection {
    flex-direction: column;
  }
}
