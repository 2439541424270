.site-footer {
    background-color: #333; /* Dark background for the footer */
    color: #fff; /* White text color */
    padding: 5px; /* Padding inside the footer */
    text-align: center; /* Center the text */
    border-top: 1px solid #444; /* A subtle border at the top */
    /* height: auto; */
    flex-shrink: 0;
  }
  
  .footer-content {
    max-width: 1200px; /* Max width for the content, can be adjusted */
    margin: 0 auto; /* Center the content block */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; /* Stack items vertically */
    /* height: 40px;; */
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .footer-content {
      flex-direction: row; /* Align items in a row on larger screens */
    }
  }
  