.story-summary {
    display: flex;
    flex-direction: column; /* Stack children elements in a column */
    margin-bottom: 20px;
    width: 250px; /* Set a fixed width to match your design */
    background-color: #f2f2f2; /* Background color as per the screenshot */
    border-radius: 8px; /* Rounded corners as per the screenshot */
    overflow: hidden; /* Ensures nothing spills outside of the border radius */
  }
  
  .story-summary img {
    width: 250px; /* Image takes the full width of the container */
    height: 166px; /* Height is auto to maintain aspect ratio */
    object-fit: cover; /* Ensures the image covers the area, might crop */
  }

  .story-summary-link {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Inherits text color from parent */
    display: block; /* Makes the link fill the container */
  }
  
  .story-content {
    padding: 10px; /* Padding inside the text content area */
    border-radius: 10px; /* Rounded corners for the paragraph */
    width: 240px;
    height: 166px;
  }
  
  .story-content h2 {
    margin-top: 0;
    font-size: 1em; /* Adjust the font size as needed */
    font-weight: bold; /* Make the headline bold */
    border-radius: 10px; /* Rounded corners for the paragraph */
  }
  
  .story-content p {
    margin: 0;
    font-size: 0.8em; /* Smaller font size for the paragraph */
    border-radius: 10px; /* Rounded corners for the paragraph */
  }
  