.site-masthead {
  padding: 8px 0;
  text-align: left;
  flex-direction: column; /* Stack children elements in a column */
}

.masthead-title {
  max-width: 1200px;
  margin: 0 auto; /* Centering the masthead content */
  color: white;
  padding: 0 10px; /* Add padding on the sides */
}

.masthead-title h1 {
  margin: 0;
  margin-top: -10px;
  padding: 0;
  font-size: 2em; /* Adjust the size as necessary */
}

.masthead-nav {
  max-width: 1200px;
  margin: 0 auto; /* Centering the masthead content */
}

.masthead-nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.masthead-nav ul li:not(:last-child) {
  border-right: 1px solid white; /* White vertical divider */
  margin-right: 10px; /* Add some right margin if necessary */
  padding-right: 10px; /* Add some padding to space out the text from the divider */
}

.masthead-nav ul li {
  display: inline-block; /* Needed to apply individual borders */
  margin-left: 0px; /* Space out the navigation links */
}

.masthead-nav ul li a {
  color: white;
  text-decoration: none;
  padding: 5px 10px; /* Padding for clickable area */
  border-bottom: 2px solid transparent; /* Transparent border by default */
  transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Transition for hover effect */
}

.masthead-nav ul li a.active {
  border-bottom: 2px solid white; /* White border for the active link */
}

.masthead-nav ul li a:hover {
  color: #eff0f1; /* Slightly lighter color on hover */
  border-bottom-color: white; /* White border on hover */
}

/* Additional responsive styles if necessary */
@media (max-width: 768px) {
  .masthead-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .masthead-nav ul li {
    width: 100%; /* Full width links on small screens */
    text-align: center;
  }

  .masthead-nav ul li a {
    display: block; /* Make the anchor fill the list item */
    padding: 10px; /* Bigger tap area for touch devices */
  }
}
