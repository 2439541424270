.page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero-section {
    display: flex;
    justify-content: center; /* Center the image horizontally */
    margin-bottom: 20px;
  }
  
  .main-image {
    max-width: 768px; /* Make sure the image is responsive */
    height: 512px;
    border-radius: 8px; /* Optional: rounded corners for the image */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for depth */
  }
  
  .content-section, .meter-reading-section {
    background-color: #fff; /* A light background for the content */
    padding: 20px;
    border-radius: 8px; /* Optional: rounded corners for the content sections */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for depth */
    margin-bottom: 20px;
  }
  
  .content-section h1, .meter-reading-section h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  